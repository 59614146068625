<div class="stars" />

<style>
    .stars {
        margin-left: calc((100vw - 1920px) / 2);
        z-index: -1;
        border-radius: 50%;
        width: 1px;
        height: 1px;
        background: transparent;
        box-shadow: 1727px 738px #fff, 1131px 1464px #fff, 643px 488px #fff,
            731px 916px #fff, 93px 1677px #fff, 1987px 174px #fff,
            1838px 690px #fff, 214px 171px #fff, 438px 355px #fff,
            203px 866px #fff, 1489px 1630px #fff, 410px 1535px #fff,
            1247px 156px #fff, 712px 320px #fff, 806px 767px #fff,
            1983px 201px #fff, 1343px 713px #fff, 1882px 323px #fff,
            1905px 1136px #fff, 474px 1793px #fff, 951px 667px #fff,
            524px 544px #fff, 1581px 749px #fff, 1364px 305px #fff,
            730px 130px #fff, 931px 356px #fff, 626px 289px #fff,
            1521px 1305px #fff, 676px 1569px #fff, 978px 1569px #fff,
            1876px 1880px #fff, 495px 1424px #fff, 1625px 1288px #fff,
            1135px 1680px #fff, 199px 1405px #fff, 991px 1337px #fff,
            248px 553px #fff, 1206px 1794px #fff, 309px 1679px #fff,
            840px 1468px #fff, 1234px 885px #fff, 668px 1804px #fff,
            1207px 521px #fff, 1087px 321px #fff, 861px 183px #fff,
            875px 1285px #fff, 1319px 1009px #fff, 1496px 1769px #fff,
            1957px 918px #fff, 1139px 1760px #fff, 1173px 1517px #fff,
            1173px 1940px #fff, 1795px 226px #fff, 434px 1076px #fff,
            701px 1163px #fff, 1567px 138px #fff, 856px 542px #fff,
            684px 1099px #fff, 650px 974px #fff, 1044px 501px #fff,
            435px 797px #fff, 498px 1047px #fff, 1631px 1694px #fff,
            398px 1382px #fff, 241px 1229px #fff, 340px 633px #fff,
            1051px 1262px #fff, 483px 1791px #fff, 1515px 1376px #fff,
            217px 339px #fff, 818px 1757px #fff, 1644px 1287px #fff,
            1460px 1828px #fff, 1256px 702px #fff, 754px 233px #fff,
            283px 145px #fff, 1811px 1250px #fff, 1711px 1433px #fff,
            1313px 1258px #fff, 1524px 1622px #fff, 31px 1498px #fff,
            1341px 1379px #fff, 612px 53px #fff, 174px 1645px #fff,
            484px 563px #fff, 1889px 956px #fff, 716px 162px #fff,
            660px 1762px #fff, 795px 656px #fff, 836px 535px #fff,
            851px 1491px #fff, 1318px 281px #fff, 652px 334px #fff,
            378px 1702px #fff, 718px 393px #fff, 1031px 1830px #fff,
            131px 1668px #fff, 1159px 698px #fff, 1065px 350px #fff,
            654px 654px #fff, 1516px 255px #fff, 1478px 129px #fff,
            279px 1892px #fff, 1822px 1729px #fff, 1324px 1616px #fff,
            982px 403px #fff, 321px 581px #fff, 945px 10px #fff,
            1151px 631px #fff, 846px 1393px #fff, 1694px 1598px #fff,
            1678px 1650px #fff, 907px 590px #fff, 423px 839px #fff,
            582px 1753px #fff, 132px 1646px #fff, 1267px 430px #fff,
            939px 1476px #fff, 572px 611px #fff, 554px 200px #fff,
            1711px 409px #fff, 519px 213px #fff, 745px 1557px #fff,
            672px 78px #fff, 1033px 987px #fff, 600px 1782px #fff,
            1988px 386px #fff, 1130px 1265px #fff, 93px 711px #fff,
            1169px 766px #fff, 817px 1660px #fff, 1511px 492px #fff,
            729px 1765px #fff, 192px 624px #fff, 1881px 1856px #fff,
            992px 402px #fff, 1326px 375px #fff, 333px 1895px #fff,
            1263px 1624px #fff, 160px 224px #fff, 371px 1116px #fff,
            1647px 4px #fff, 1657px 1146px #fff, 1414px 1432px #fff,
            481px 802px #fff, 1564px 171px #fff, 1008px 268px #fff,
            1748px 1893px #fff, 56px 467px #fff, 692px 1640px #fff,
            497px 247px #fff, 1351px 449px #fff, 1459px 1417px #fff,
            1077px 1797px #fff, 1250px 804px #fff, 1854px 1693px #fff,
            298px 99px #fff, 1604px 1591px #fff, 298px 695px #fff,
            562px 893px #fff, 1802px 480px #fff, 401px 1782px #fff,
            360px 1580px #fff, 788px 1961px #fff, 1453px 847px #fff,
            1448px 1186px #fff, 1788px 1679px #fff, 346px 1929px #fff,
            446px 638px #fff, 365px 1562px #fff, 1947px 1951px #fff,
            805px 479px #fff, 306px 1488px #fff, 734px 399px #fff,
            269px 577px #fff, 1580px 493px #fff, 951px 1771px #fff,
            1662px 1460px #fff, 1776px 251px #fff, 975px 1805px #fff,
            1097px 1513px #fff, 1868px 1899px #fff, 1975px 87px #fff,
            353px 160px #fff, 1589px 1325px #fff, 198px 1211px #fff,
            1293px 1808px #fff, 1468px 998px #fff, 1011px 1200px #fff,
            1806px 1267px #fff, 704px 244px #fff, 66px 1036px #fff,
            775px 1021px #fff, 1745px 1963px #fff, 149px 659px #fff,
            1853px 611px #fff, 1888px 371px #fff, 1470px 1761px #fff,
            746px 537px #fff, 1916px 768px #fff, 1858px 535px #fff,
            1657px 426px #fff, 1408px 1889px #fff, 995px 1043px #fff,
            29px 726px #fff, 39px 1802px #fff, 648px 55px #fff,
            691px 1992px #fff, 187px 1184px #fff, 889px 1186px #fff,
            1568px 1122px #fff, 223px 1963px #fff, 115px 979px #fff,
            1772px 1781px #fff, 1974px 1595px #fff, 64px 1994px #fff,
            1390px 50px #fff, 1381px 1728px #fff, 1037px 954px #fff,
            443px 1044px #fff, 1657px 1918px #fff, 1239px 1245px #fff,
            1587px 1564px #fff, 1364px 1431px #fff, 1210px 1217px #fff,
            1771px 1869px #fff, 1716px 1775px #fff, 139px 198px #fff,
            1159px 1234px #fff, 208px 1036px #fff, 527px 1742px #fff,
            1892px 93px #fff, 1330px 7px #fff, 234px 864px #fff,
            1822px 1244px #fff, 798px 298px #fff, 955px 1074px #fff,
            1035px 57px #fff, 1015px 617px #fff, 217px 1865px #fff,
            598px 215px #fff, 1529px 940px #fff, 860px 1961px #fff,
            719px 607px #fff, 958px 1378px #fff, 930px 60px #fff,
            339px 1882px #fff, 884px 947px #fff, 101px 112px #fff,
            1314px 1583px #fff, 2px 140px #fff, 121px 59px #fff,
            141px 1788px #fff, 169px 1376px #fff, 804px 1618px #fff,
            971px 931px #fff, 564px 516px #fff, 1403px 853px #fff,
            557px 993px #fff, 1334px 1922px #fff, 426px 656px #fff,
            892px 881px #fff, 1887px 1451px #fff, 1971px 1000px #fff,
            956px 1725px #fff, 1216px 1887px #fff, 1140px 30px #fff,
            1652px 890px #fff, 399px 116px #fff, 1680px 44px #fff,
            121px 523px #fff, 1610px 1977px #fff, 597px 1530px #fff,
            888px 1309px #fff, 1567px 1702px #fff, 1998px 1514px #fff,
            1607px 67px #fff, 1158px 1147px #fff, 1926px 135px #fff,
            1499px 35px #fff, 332px 593px #fff, 1591px 1780px #fff,
            226px 583px #fff, 1687px 1017px #fff, 1829px 1384px #fff,
            1418px 1832px #fff, 1718px 1865px #fff, 726px 1641px #fff,
            1339px 1946px #fff, 791px 1766px #fff, 609px 374px #fff,
            388px 1888px #fff, 968px 1171px #fff, 128px 679px #fff,
            778px 1920px #fff, 1011px 1847px #fff, 376px 44px #fff,
            1389px 62px #fff, 286px 1797px #fff, 644px 732px #fff,
            410px 1511px #fff, 584px 251px #fff, 1460px 778px #fff,
            666px 1909px #fff, 1965px 1132px #fff, 1644px 1878px #fff,
            1108px 1296px #fff, 1102px 186px #fff, 988px 1664px #fff,
            386px 202px #fff, 1222px 122px #fff, 921px 783px #fff,
            494px 1202px #fff, 62px 61px #fff, 1609px 1422px #fff,
            1464px 679px #fff, 618px 376px #fff, 1870px 871px #fff,
            1741px 567px #fff, 135px 1092px #fff, 1603px 1467px #fff,
            1646px 1077px #fff, 1566px 35px #fff, 744px 1721px #fff,
            182px 98px #fff, 1569px 161px #fff, 966px 1766px #fff,
            815px 1846px #fff, 122px 1011px #fff, 928px 635px #fff,
            747px 580px #fff, 341px 1493px #fff, 1982px 751px #fff,
            1050px 317px #fff, 1106px 1956px #fff, 1188px 1318px #fff,
            743px 1417px #fff, 57px 1520px #fff, 40px 1898px #fff,
            1167px 664px #fff, 1205px 1249px #fff, 1508px 348px #fff,
            516px 1957px #fff, 1081px 287px #fff, 548px 1250px #fff,
            1290px 536px #fff, 531px 51px #fff, 757px 603px #fff,
            373px 444px #fff, 1178px 1017px #fff, 1773px 1562px #fff,
            1447px 247px #fff, 354px 1463px #fff, 449px 72px #fff,
            1192px 158px #fff, 45px 27px #fff, 693px 124px #fff,
            1758px 92px #fff, 349px 430px #fff, 362px 348px #fff,
            757px 1663px #fff, 1667px 1872px #fff, 452px 1897px #fff,
            1883px 997px #fff, 1458px 1087px #fff, 101px 127px #fff,
            848px 960px #fff, 1498px 837px #fff, 772px 482px #fff,
            1841px 1393px #fff, 1037px 1739px #fff, 1955px 248px #fff,
            1396px 1956px #fff, 864px 766px #fff, 349px 17px #fff,
            278px 1936px #fff, 230px 635px #fff, 109px 689px #fff,
            1539px 1774px #fff, 773px 1525px #fff, 322px 251px #fff,
            497px 945px #fff, 668px 1432px #fff, 1389px 1652px #fff,
            374px 1034px #fff, 536px 773px #fff, 1129px 1319px #fff,
            641px 562px #fff, 1016px 268px #fff, 953px 768px #fff,
            1601px 389px #fff, 1531px 1167px #fff, 240px 13px #fff,
            684px 1130px #fff, 1871px 552px #fff, 366px 123px #fff,
            1574px 1501px #fff, 1094px 1191px #fff, 1389px 501px #fff,
            538px 508px #fff, 300px 121px #fff, 1682px 682px #fff,
            942px 753px #fff, 212px 1890px #fff, 1954px 830px #fff,
            1930px 1200px #fff, 508px 1618px #fff, 1813px 944px #fff,
            1999px 1171px #fff, 495px 488px #fff, 298px 714px #fff,
            151px 1321px #fff, 817px 400px #fff, 346px 995px #fff,
            1730px 1288px #fff, 408px 1613px #fff, 1284px 748px #fff,
            1580px 911px #fff, 1114px 1496px #fff, 468px 1131px #fff,
            894px 704px #fff, 745px 115px #fff, 1080px 1180px #fff,
            354px 151px #fff, 1584px 363px #fff, 1222px 1339px #fff,
            941px 1268px #fff, 1942px 260px #fff, 921px 382px #fff,
            1801px 831px #fff, 1236px 994px #fff, 81px 882px #fff,
            1265px 493px #fff, 1583px 1219px #fff, 146px 684px #fff,
            42px 1250px #fff, 1923px 789px #fff, 759px 807px #fff,
            1996px 1633px #fff, 1866px 1237px #fff, 1723px 910px #fff,
            1612px 1621px #fff, 1964px 888px #fff, 729px 1766px #fff,
            1778px 1881px #fff, 91px 1718px #fff, 648px 1133px #fff,
            1917px 295px #fff, 1247px 986px #fff, 866px 395px #fff,
            939px 1499px #fff, 1578px 909px #fff, 1781px 71px #fff,
            1407px 1794px #fff, 560px 121px #fff, 273px 1641px #fff,
            1938px 1527px #fff, 1681px 1876px #fff, 247px 1156px #fff,
            122px 385px #fff, 1522px 1831px #fff, 1522px 1012px #fff,
            211px 590px #fff, 1885px 383px #fff, 1429px 772px #fff,
            1338px 1701px #fff, 1651px 1883px #fff, 1973px 272px #fff,
            280px 1521px #fff, 1658px 1629px #fff, 249px 92px #fff,
            1676px 930px #fff, 855px 1797px #fff, 416px 1279px #fff,
            333px 1395px #fff, 238px 1733px #fff, 438px 454px #fff,
            1819px 230px #fff, 60px 887px #fff, 203px 1329px #fff,
            1162px 1299px #fff, 1595px 1504px #fff, 783px 236px #fff,
            781px 1406px #fff, 1981px 1524px #fff, 1387px 1280px #fff,
            1519px 1930px #fff, 563px 1747px #fff, 716px 179px #fff,
            1915px 1703px #fff, 764px 527px #fff, 269px 1818px #fff,
            558px 1481px #fff, 697px 940px #fff, 1042px 1637px #fff,
            479px 1487px #fff, 194px 1437px #fff, 217px 1396px #fff,
            374px 1457px #fff, 1051px 407px #fff, 1541px 405px #fff,
            219px 710px #fff, 279px 873px #fff, 313px 1833px #fff,
            1920px 604px #fff, 1558px 1947px #fff, 1845px 1395px #fff,
            103px 312px #fff, 153px 1338px #fff, 1840px 94px #fff,
            1497px 1567px #fff, 843px 1452px #fff, 1134px 1135px #fff,
            751px 546px #fff, 361px 361px #fff, 1673px 1920px #fff,
            1431px 1596px #fff, 1736px 1248px #fff, 764px 1130px #fff,
            1301px 1384px #fff, 207px 1343px #fff, 850px 76px #fff,
            1932px 1674px #fff, 806px 344px #fff, 421px 283px #fff,
            104px 928px #fff, 1342px 705px #fff, 1443px 280px #fff,
            382px 370px #fff, 817px 1338px #fff, 530px 1214px #fff,
            131px 941px #fff, 1480px 1911px #fff, 716px 1520px #fff,
            143px 616px #fff, 148px 1714px #fff, 141px 1426px #fff,
            590px 547px #fff, 508px 1311px #fff, 1214px 1760px #fff,
            1193px 1883px #fff, 1828px 1149px #fff, 674px 108px #fff,
            429px 1180px #fff, 1744px 591px #fff, 1198px 1322px #fff,
            1386px 1606px #fff, 581px 551px #fff, 338px 1658px #fff,
            66px 460px #fff, 1709px 766px #fff, 1188px 1124px #fff,
            673px 1409px #fff, 1868px 1915px #fff, 757px 1373px #fff,
            1331px 1320px #fff, 545px 789px #fff, 972px 531px #fff,
            66px 1649px #fff, 849px 1239px #fff, 1803px 1284px #fff,
            1062px 979px #fff, 496px 1561px #fff, 550px 1622px #fff,
            1715px 1204px #fff, 938px 545px #fff, 1600px 1324px #fff,
            933px 1544px #fff, 738px 1912px #fff, 1725px 402px #fff,
            1009px 1929px #fff, 1930px 1910px #fff, 306px 264px #fff,
            1418px 477px #fff, 1014px 892px #fff, 359px 707px #fff,
            886px 869px #fff, 1561px 729px #fff, 662px 1738px #fff,
            1529px 1061px #fff, 668px 1624px #fff, 1815px 889px #fff,
            972px 1364px #fff, 1505px 885px #fff, 864px 687px #fff,
            1701px 1462px #fff, 474px 927px #fff, 709px 406px #fff,
            1159px 1094px #fff, 1741px 149px #fff, 1973px 167px #fff,
            335px 1627px #fff, 1777px 371px #fff, 437px 1920px #fff,
            116px 271px #fff, 1349px 183px #fff, 1740px 1160px #fff,
            932px 360px #fff, 907px 1649px #fff, 1155px 1939px #fff,
            1366px 1244px #fff, 310px 1115px #fff, 1972px 393px #fff,
            1909px 1655px #fff, 506px 613px #fff, 1068px 1379px #fff,
            242px 1097px #fff, 1213px 1041px #fff, 47px 191px #fff,
            1811px 1264px #fff, 1656px 736px #fff, 1284px 1368px #fff,
            302px 1836px #fff, 199px 550px #fff, 1353px 938px #fff,
            721px 146px #fff, 1946px 1026px #fff, 1537px 1874px #fff,
            146px 396px #fff, 1326px 1103px #fff, 487px 445px #fff,
            1288px 805px #fff, 426px 1784px #fff, 380px 629px #fff,
            358px 1316px #fff, 833px 1445px #fff, 803px 927px #fff,
            1971px 687px #fff, 887px 968px #fff, 1462px 152px #fff,
            131px 597px #fff, 318px 1665px #fff, 1957px 541px #fff,
            1118px 1116px #fff, 1635px 1220px #fff, 313px 195px #fff,
            1356px 1129px #fff, 1900px 1443px #fff, 1028px 100px #fff,
            796px 178px #fff, 1181px 1377px #fff, 1772px 538px #fff,
            406px 919px #fff, 1115px 1793px #fff, 1869px 1922px #fff,
            1002px 1721px #fff, 517px 1097px #fff, 202px 1440px #fff,
            605px 1490px #fff, 1217px 1514px #fff, 1401px 1839px #fff,
            372px 1940px #fff, 1406px 1015px #fff, 15px 1474px #fff,
            1415px 98px #fff, 927px 203px #fff, 1045px 530px #fff,
            74px 1274px #fff, 1168px 478px #fff, 419px 297px #fff,
            1819px 1526px #fff, 1006px 159px #fff, 817px 1314px #fff,
            118px 970px #fff, 1951px 1434px #fff, 1860px 741px #fff,
            933px 1094px #fff, 113px 1371px #fff, 73px 1688px #fff,
            376px 820px #fff, 1073px 1698px #fff, 1912px 673px #fff,
            1678px 498px #fff, 671px 209px #fff, 1115px 1147px #fff,
            399px 1705px #fff, 1096px 1446px #fff, 1932px 1599px #fff,
            1729px 1138px #fff, 1962px 436px #fff, 1761px 934px #fff,
            651px 1630px #fff, 1045px 392px #fff, 1905px 89px #fff,
            887px 252px #fff, 794px 1392px #fff, 1857px 1362px #fff,
            305px 1925px #fff, 966px 500px #fff, 1379px 1237px #fff,
            1959px 1476px #fff, 1597px 1521px #fff, 58px 1720px #fff,
            1480px 1153px #fff, 1322px 793px #fff, 1053px 1941px #fff,
            214px 711px #fff, 1245px 816px #fff, 1891px 1386px #fff,
            1479px 1454px #fff;
        animation: animStar 50s linear infinite;
    }

    .stars:after {
        content: " ";
        position: absolute;
        top: 2000px;
        width: 1px;
        height: 1px;
        background: transparent;
        box-shadow: 1727px 738px #fff, 1131px 1464px #fff, 643px 488px #fff,
            731px 916px #fff, 93px 1677px #fff, 1987px 174px #fff,
            1838px 690px #fff, 214px 171px #fff, 438px 355px #fff,
            203px 866px #fff, 1489px 1630px #fff, 410px 1535px #fff,
            1247px 156px #fff, 712px 320px #fff, 806px 767px #fff,
            1983px 201px #fff, 1343px 713px #fff, 1882px 323px #fff,
            1905px 1136px #fff, 474px 1793px #fff, 951px 667px #fff,
            524px 544px #fff, 1581px 749px #fff, 1364px 305px #fff,
            730px 130px #fff, 931px 356px #fff, 626px 289px #fff,
            1521px 1305px #fff, 676px 1569px #fff, 978px 1569px #fff,
            1876px 1880px #fff, 495px 1424px #fff, 1625px 1288px #fff,
            1135px 1680px #fff, 199px 1405px #fff, 991px 1337px #fff,
            248px 553px #fff, 1206px 1794px #fff, 309px 1679px #fff,
            840px 1468px #fff, 1234px 885px #fff, 668px 1804px #fff,
            1207px 521px #fff, 1087px 321px #fff, 861px 183px #fff,
            875px 1285px #fff, 1319px 1009px #fff, 1496px 1769px #fff,
            1957px 918px #fff, 1139px 1760px #fff, 1173px 1517px #fff,
            1173px 1940px #fff, 1795px 226px #fff, 434px 1076px #fff,
            701px 1163px #fff, 1567px 138px #fff, 856px 542px #fff,
            684px 1099px #fff, 650px 974px #fff, 1044px 501px #fff,
            435px 797px #fff, 498px 1047px #fff, 1631px 1694px #fff,
            398px 1382px #fff, 241px 1229px #fff, 340px 633px #fff,
            1051px 1262px #fff, 483px 1791px #fff, 1515px 1376px #fff,
            217px 339px #fff, 818px 1757px #fff, 1644px 1287px #fff,
            1460px 1828px #fff, 1256px 702px #fff, 754px 233px #fff,
            283px 145px #fff, 1811px 1250px #fff, 1711px 1433px #fff,
            1313px 1258px #fff, 1524px 1622px #fff, 31px 1498px #fff,
            1341px 1379px #fff, 612px 53px #fff, 174px 1645px #fff,
            484px 563px #fff, 1889px 956px #fff, 716px 162px #fff,
            660px 1762px #fff, 795px 656px #fff, 836px 535px #fff,
            851px 1491px #fff, 1318px 281px #fff, 652px 334px #fff,
            378px 1702px #fff, 718px 393px #fff, 1031px 1830px #fff,
            131px 1668px #fff, 1159px 698px #fff, 1065px 350px #fff,
            654px 654px #fff, 1516px 255px #fff, 1478px 129px #fff,
            279px 1892px #fff, 1822px 1729px #fff, 1324px 1616px #fff,
            982px 403px #fff, 321px 581px #fff, 945px 10px #fff,
            1151px 631px #fff, 846px 1393px #fff, 1694px 1598px #fff,
            1678px 1650px #fff, 907px 590px #fff, 423px 839px #fff,
            582px 1753px #fff, 132px 1646px #fff, 1267px 430px #fff,
            939px 1476px #fff, 572px 611px #fff, 554px 200px #fff,
            1711px 409px #fff, 519px 213px #fff, 745px 1557px #fff,
            672px 78px #fff, 1033px 987px #fff, 600px 1782px #fff,
            1988px 386px #fff, 1130px 1265px #fff, 93px 711px #fff,
            1169px 766px #fff, 817px 1660px #fff, 1511px 492px #fff,
            729px 1765px #fff, 192px 624px #fff, 1881px 1856px #fff,
            992px 402px #fff, 1326px 375px #fff, 333px 1895px #fff,
            1263px 1624px #fff, 160px 224px #fff, 371px 1116px #fff,
            1647px 4px #fff, 1657px 1146px #fff, 1414px 1432px #fff,
            481px 802px #fff, 1564px 171px #fff, 1008px 268px #fff,
            1748px 1893px #fff, 56px 467px #fff, 692px 1640px #fff,
            497px 247px #fff, 1351px 449px #fff, 1459px 1417px #fff,
            1077px 1797px #fff, 1250px 804px #fff, 1854px 1693px #fff,
            298px 99px #fff, 1604px 1591px #fff, 298px 695px #fff,
            562px 893px #fff, 1802px 480px #fff, 401px 1782px #fff,
            360px 1580px #fff, 788px 1961px #fff, 1453px 847px #fff,
            1448px 1186px #fff, 1788px 1679px #fff, 346px 1929px #fff,
            446px 638px #fff, 365px 1562px #fff, 1947px 1951px #fff,
            805px 479px #fff, 306px 1488px #fff, 734px 399px #fff,
            269px 577px #fff, 1580px 493px #fff, 951px 1771px #fff,
            1662px 1460px #fff, 1776px 251px #fff, 975px 1805px #fff,
            1097px 1513px #fff, 1868px 1899px #fff, 1975px 87px #fff,
            353px 160px #fff, 1589px 1325px #fff, 198px 1211px #fff,
            1293px 1808px #fff, 1468px 998px #fff, 1011px 1200px #fff,
            1806px 1267px #fff, 704px 244px #fff, 66px 1036px #fff,
            775px 1021px #fff, 1745px 1963px #fff, 149px 659px #fff,
            1853px 611px #fff, 1888px 371px #fff, 1470px 1761px #fff,
            746px 537px #fff, 1916px 768px #fff, 1858px 535px #fff,
            1657px 426px #fff, 1408px 1889px #fff, 995px 1043px #fff,
            29px 726px #fff, 39px 1802px #fff, 648px 55px #fff,
            691px 1992px #fff, 187px 1184px #fff, 889px 1186px #fff,
            1568px 1122px #fff, 223px 1963px #fff, 115px 979px #fff,
            1772px 1781px #fff, 1974px 1595px #fff, 64px 1994px #fff,
            1390px 50px #fff, 1381px 1728px #fff, 1037px 954px #fff,
            443px 1044px #fff, 1657px 1918px #fff, 1239px 1245px #fff,
            1587px 1564px #fff, 1364px 1431px #fff, 1210px 1217px #fff,
            1771px 1869px #fff, 1716px 1775px #fff, 139px 198px #fff,
            1159px 1234px #fff, 208px 1036px #fff, 527px 1742px #fff,
            1892px 93px #fff, 1330px 7px #fff, 234px 864px #fff,
            1822px 1244px #fff, 798px 298px #fff, 955px 1074px #fff,
            1035px 57px #fff, 1015px 617px #fff, 217px 1865px #fff,
            598px 215px #fff, 1529px 940px #fff, 860px 1961px #fff,
            719px 607px #fff, 958px 1378px #fff, 930px 60px #fff,
            339px 1882px #fff, 884px 947px #fff, 101px 112px #fff,
            1314px 1583px #fff, 2px 140px #fff, 121px 59px #fff,
            141px 1788px #fff, 169px 1376px #fff, 804px 1618px #fff,
            971px 931px #fff, 564px 516px #fff, 1403px 853px #fff,
            557px 993px #fff, 1334px 1922px #fff, 426px 656px #fff,
            892px 881px #fff, 1887px 1451px #fff, 1971px 1000px #fff,
            956px 1725px #fff, 1216px 1887px #fff, 1140px 30px #fff,
            1652px 890px #fff, 399px 116px #fff, 1680px 44px #fff,
            121px 523px #fff, 1610px 1977px #fff, 597px 1530px #fff,
            888px 1309px #fff, 1567px 1702px #fff, 1998px 1514px #fff,
            1607px 67px #fff, 1158px 1147px #fff, 1926px 135px #fff,
            1499px 35px #fff, 332px 593px #fff, 1591px 1780px #fff,
            226px 583px #fff, 1687px 1017px #fff, 1829px 1384px #fff,
            1418px 1832px #fff, 1718px 1865px #fff, 726px 1641px #fff,
            1339px 1946px #fff, 791px 1766px #fff, 609px 374px #fff,
            388px 1888px #fff, 968px 1171px #fff, 128px 679px #fff,
            778px 1920px #fff, 1011px 1847px #fff, 376px 44px #fff,
            1389px 62px #fff, 286px 1797px #fff, 644px 732px #fff,
            410px 1511px #fff, 584px 251px #fff, 1460px 778px #fff,
            666px 1909px #fff, 1965px 1132px #fff, 1644px 1878px #fff,
            1108px 1296px #fff, 1102px 186px #fff, 988px 1664px #fff,
            386px 202px #fff, 1222px 122px #fff, 921px 783px #fff,
            494px 1202px #fff, 62px 61px #fff, 1609px 1422px #fff,
            1464px 679px #fff, 618px 376px #fff, 1870px 871px #fff,
            1741px 567px #fff, 135px 1092px #fff, 1603px 1467px #fff,
            1646px 1077px #fff, 1566px 35px #fff, 744px 1721px #fff,
            182px 98px #fff, 1569px 161px #fff, 966px 1766px #fff,
            815px 1846px #fff, 122px 1011px #fff, 928px 635px #fff,
            747px 580px #fff, 341px 1493px #fff, 1982px 751px #fff,
            1050px 317px #fff, 1106px 1956px #fff, 1188px 1318px #fff,
            743px 1417px #fff, 57px 1520px #fff, 40px 1898px #fff,
            1167px 664px #fff, 1205px 1249px #fff, 1508px 348px #fff,
            516px 1957px #fff, 1081px 287px #fff, 548px 1250px #fff,
            1290px 536px #fff, 531px 51px #fff, 757px 603px #fff,
            373px 444px #fff, 1178px 1017px #fff, 1773px 1562px #fff,
            1447px 247px #fff, 354px 1463px #fff, 449px 72px #fff,
            1192px 158px #fff, 45px 27px #fff, 693px 124px #fff,
            1758px 92px #fff, 349px 430px #fff, 362px 348px #fff,
            757px 1663px #fff, 1667px 1872px #fff, 452px 1897px #fff,
            1883px 997px #fff, 1458px 1087px #fff, 101px 127px #fff,
            848px 960px #fff, 1498px 837px #fff, 772px 482px #fff,
            1841px 1393px #fff, 1037px 1739px #fff, 1955px 248px #fff,
            1396px 1956px #fff, 864px 766px #fff, 349px 17px #fff,
            278px 1936px #fff, 230px 635px #fff, 109px 689px #fff,
            1539px 1774px #fff, 773px 1525px #fff, 322px 251px #fff,
            497px 945px #fff, 668px 1432px #fff, 1389px 1652px #fff,
            374px 1034px #fff, 536px 773px #fff, 1129px 1319px #fff,
            641px 562px #fff, 1016px 268px #fff, 953px 768px #fff,
            1601px 389px #fff, 1531px 1167px #fff, 240px 13px #fff,
            684px 1130px #fff, 1871px 552px #fff, 366px 123px #fff,
            1574px 1501px #fff, 1094px 1191px #fff, 1389px 501px #fff,
            538px 508px #fff, 300px 121px #fff, 1682px 682px #fff,
            942px 753px #fff, 212px 1890px #fff, 1954px 830px #fff,
            1930px 1200px #fff, 508px 1618px #fff, 1813px 944px #fff,
            1999px 1171px #fff, 495px 488px #fff, 298px 714px #fff,
            151px 1321px #fff, 817px 400px #fff, 346px 995px #fff,
            1730px 1288px #fff, 408px 1613px #fff, 1284px 748px #fff,
            1580px 911px #fff, 1114px 1496px #fff, 468px 1131px #fff,
            894px 704px #fff, 745px 115px #fff, 1080px 1180px #fff,
            354px 151px #fff, 1584px 363px #fff, 1222px 1339px #fff,
            941px 1268px #fff, 1942px 260px #fff, 921px 382px #fff,
            1801px 831px #fff, 1236px 994px #fff, 81px 882px #fff,
            1265px 493px #fff, 1583px 1219px #fff, 146px 684px #fff,
            42px 1250px #fff, 1923px 789px #fff, 759px 807px #fff,
            1996px 1633px #fff, 1866px 1237px #fff, 1723px 910px #fff,
            1612px 1621px #fff, 1964px 888px #fff, 729px 1766px #fff,
            1778px 1881px #fff, 91px 1718px #fff, 648px 1133px #fff,
            1917px 295px #fff, 1247px 986px #fff, 866px 395px #fff,
            939px 1499px #fff, 1578px 909px #fff, 1781px 71px #fff,
            1407px 1794px #fff, 560px 121px #fff, 273px 1641px #fff,
            1938px 1527px #fff, 1681px 1876px #fff, 247px 1156px #fff,
            122px 385px #fff, 1522px 1831px #fff, 1522px 1012px #fff,
            211px 590px #fff, 1885px 383px #fff, 1429px 772px #fff,
            1338px 1701px #fff, 1651px 1883px #fff, 1973px 272px #fff,
            280px 1521px #fff, 1658px 1629px #fff, 249px 92px #fff,
            1676px 930px #fff, 855px 1797px #fff, 416px 1279px #fff,
            333px 1395px #fff, 238px 1733px #fff, 438px 454px #fff,
            1819px 230px #fff, 60px 887px #fff, 203px 1329px #fff,
            1162px 1299px #fff, 1595px 1504px #fff, 783px 236px #fff,
            781px 1406px #fff, 1981px 1524px #fff, 1387px 1280px #fff,
            1519px 1930px #fff, 563px 1747px #fff, 716px 179px #fff,
            1915px 1703px #fff, 764px 527px #fff, 269px 1818px #fff,
            558px 1481px #fff, 697px 940px #fff, 1042px 1637px #fff,
            479px 1487px #fff, 194px 1437px #fff, 217px 1396px #fff,
            374px 1457px #fff, 1051px 407px #fff, 1541px 405px #fff,
            219px 710px #fff, 279px 873px #fff, 313px 1833px #fff,
            1920px 604px #fff, 1558px 1947px #fff, 1845px 1395px #fff,
            103px 312px #fff, 153px 1338px #fff, 1840px 94px #fff,
            1497px 1567px #fff, 843px 1452px #fff, 1134px 1135px #fff,
            751px 546px #fff, 361px 361px #fff, 1673px 1920px #fff,
            1431px 1596px #fff, 1736px 1248px #fff, 764px 1130px #fff,
            1301px 1384px #fff, 207px 1343px #fff, 850px 76px #fff,
            1932px 1674px #fff, 806px 344px #fff, 421px 283px #fff,
            104px 928px #fff, 1342px 705px #fff, 1443px 280px #fff,
            382px 370px #fff, 817px 1338px #fff, 530px 1214px #fff,
            131px 941px #fff, 1480px 1911px #fff, 716px 1520px #fff,
            143px 616px #fff, 148px 1714px #fff, 141px 1426px #fff,
            590px 547px #fff, 508px 1311px #fff, 1214px 1760px #fff,
            1193px 1883px #fff, 1828px 1149px #fff, 674px 108px #fff,
            429px 1180px #fff, 1744px 591px #fff, 1198px 1322px #fff,
            1386px 1606px #fff, 581px 551px #fff, 338px 1658px #fff,
            66px 460px #fff, 1709px 766px #fff, 1188px 1124px #fff,
            673px 1409px #fff, 1868px 1915px #fff, 757px 1373px #fff,
            1331px 1320px #fff, 545px 789px #fff, 972px 531px #fff,
            66px 1649px #fff, 849px 1239px #fff, 1803px 1284px #fff,
            1062px 979px #fff, 496px 1561px #fff, 550px 1622px #fff,
            1715px 1204px #fff, 938px 545px #fff, 1600px 1324px #fff,
            933px 1544px #fff, 738px 1912px #fff, 1725px 402px #fff,
            1009px 1929px #fff, 1930px 1910px #fff, 306px 264px #fff,
            1418px 477px #fff, 1014px 892px #fff, 359px 707px #fff,
            886px 869px #fff, 1561px 729px #fff, 662px 1738px #fff,
            1529px 1061px #fff, 668px 1624px #fff, 1815px 889px #fff,
            972px 1364px #fff, 1505px 885px #fff, 864px 687px #fff,
            1701px 1462px #fff, 474px 927px #fff, 709px 406px #fff,
            1159px 1094px #fff, 1741px 149px #fff, 1973px 167px #fff,
            335px 1627px #fff, 1777px 371px #fff, 437px 1920px #fff,
            116px 271px #fff, 1349px 183px #fff, 1740px 1160px #fff,
            932px 360px #fff, 907px 1649px #fff, 1155px 1939px #fff,
            1366px 1244px #fff, 310px 1115px #fff, 1972px 393px #fff,
            1909px 1655px #fff, 506px 613px #fff, 1068px 1379px #fff,
            242px 1097px #fff, 1213px 1041px #fff, 47px 191px #fff,
            1811px 1264px #fff, 1656px 736px #fff, 1284px 1368px #fff,
            302px 1836px #fff, 199px 550px #fff, 1353px 938px #fff,
            721px 146px #fff, 1946px 1026px #fff, 1537px 1874px #fff,
            146px 396px #fff, 1326px 1103px #fff, 487px 445px #fff,
            1288px 805px #fff, 426px 1784px #fff, 380px 629px #fff,
            358px 1316px #fff, 833px 1445px #fff, 803px 927px #fff,
            1971px 687px #fff, 887px 968px #fff, 1462px 152px #fff,
            131px 597px #fff, 318px 1665px #fff, 1957px 541px #fff,
            1118px 1116px #fff, 1635px 1220px #fff, 313px 195px #fff,
            1356px 1129px #fff, 1900px 1443px #fff, 1028px 100px #fff,
            796px 178px #fff, 1181px 1377px #fff, 1772px 538px #fff,
            406px 919px #fff, 1115px 1793px #fff, 1869px 1922px #fff,
            1002px 1721px #fff, 517px 1097px #fff, 202px 1440px #fff,
            605px 1490px #fff, 1217px 1514px #fff, 1401px 1839px #fff,
            372px 1940px #fff, 1406px 1015px #fff, 15px 1474px #fff,
            1415px 98px #fff, 927px 203px #fff, 1045px 530px #fff,
            74px 1274px #fff, 1168px 478px #fff, 419px 297px #fff,
            1819px 1526px #fff, 1006px 159px #fff, 817px 1314px #fff,
            118px 970px #fff, 1951px 1434px #fff, 1860px 741px #fff,
            933px 1094px #fff, 113px 1371px #fff, 73px 1688px #fff,
            376px 820px #fff, 1073px 1698px #fff, 1912px 673px #fff,
            1678px 498px #fff, 671px 209px #fff, 1115px 1147px #fff,
            399px 1705px #fff, 1096px 1446px #fff, 1932px 1599px #fff,
            1729px 1138px #fff, 1962px 436px #fff, 1761px 934px #fff,
            651px 1630px #fff, 1045px 392px #fff, 1905px 89px #fff,
            887px 252px #fff, 794px 1392px #fff, 1857px 1362px #fff,
            305px 1925px #fff, 966px 500px #fff, 1379px 1237px #fff,
            1959px 1476px #fff, 1597px 1521px #fff, 58px 1720px #fff,
            1480px 1153px #fff, 1322px 793px #fff, 1053px 1941px #fff,
            214px 711px #fff, 1245px 816px #fff, 1891px 1386px #fff,
            1479px 1454px #fff;
    }

    @keyframes animStar {
        from {
            transform: translateY(0px);
        }

        to {
            transform: translateY(-2000px);
        }
    }
</style>
