<h1 class="title">TacoMail</h1>

<style>
    .title {
        z-index: 1000;
        font-size: 100px;
        line-height: 100px;
        text-align: center;
        letter-spacing: 13px;
        background: linear-gradient(white, #dbdde0, #38495a);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 300;
        margin-bottom: 30px;
    }

    @media screen and (max-width: 600px) {
        .title {
            font-size: 70px;
            line-height: 70px;
        }
    }

    @media screen and (max-width: 400px) {
        .title {
            font-size: 50px;
            line-height: 50px;
        }
    }
</style>
