<div class="glow" />

<style>
    .glow {
        z-index: 2;
        position: absolute;
        width: 100%;
        height: 100%;
        background: #215496;
        filter: blur(200px);
        opacity: 0.7;
        top: 90%;
        z-index: -1;
    }
</style>
