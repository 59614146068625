<div class="stars" />

<style>
    .stars {
        margin-left: calc((100vw - 1920px) / 2);
        z-index: -1;
        border-radius: 50%;
        width: 2px;
        height: 2px;
        background: transparent;
        box-shadow: 1190px 1949px #fff, 1346px 1667px #fff, 1870px 1954px #fff,
            430px 151px #fff, 1594px 817px #fff, 809px 1296px #fff,
            1761px 1022px #fff, 1367px 901px #fff, 316px 839px #fff,
            1115px 1557px #fff, 292px 1073px #fff, 901px 1329px #fff,
            900px 1643px #fff, 961px 97px #fff, 258px 900px #fff,
            93px 483px #fff, 878px 1223px #fff, 1995px 812px #fff,
            504px 463px #fff, 1373px 1522px #fff, 1162px 1132px #fff,
            820px 523px #fff, 1601px 1089px #fff, 1945px 1649px #fff,
            1903px 1366px #fff, 1425px 1657px #fff, 1046px 1349px #fff,
            1515px 598px #fff, 1932px 1128px #fff, 1380px 1206px #fff,
            652px 276px #fff, 1390px 876px #fff, 933px 1758px #fff,
            1803px 437px #fff, 711px 1756px #fff, 1894px 851px #fff,
            714px 74px #fff, 344px 1113px #fff, 1010px 1478px #fff,
            1882px 834px #fff, 1668px 1850px #fff, 1834px 1420px #fff,
            836px 896px #fff, 1716px 891px #fff, 583px 1433px #fff,
            188px 1265px #fff, 225px 100px #fff, 1385px 1765px #fff,
            340px 86px #fff, 1512px 1785px #fff, 556px 1540px #fff,
            1256px 479px #fff, 738px 1585px #fff, 1718px 1463px #fff,
            1587px 199px #fff, 939px 1413px #fff, 427px 1153px #fff,
            1344px 1960px #fff, 1290px 1682px #fff, 367px 1099px #fff,
            1196px 1478px #fff, 1323px 16px #fff, 689px 1542px #fff,
            1813px 1851px #fff, 161px 1741px #fff, 1813px 1967px #fff,
            1763px 625px #fff, 977px 1577px #fff, 1246px 1167px #fff,
            664px 310px #fff, 952px 789px #fff, 1626px 1441px #fff,
            560px 18px #fff, 1899px 1814px #fff, 140px 836px #fff,
            1925px 1027px #fff, 1342px 283px #fff, 297px 40px #fff,
            783px 169px #fff, 199px 1749px #fff, 478px 1905px #fff,
            690px 1376px #fff, 312px 693px #fff, 38px 323px #fff,
            769px 1062px #fff, 43px 1525px #fff, 1554px 1074px #fff,
            98px 1927px #fff, 169px 596px #fff, 1148px 986px #fff,
            821px 881px #fff, 1977px 697px #fff, 1709px 658px #fff,
            894px 1948px #fff, 1305px 750px #fff, 168px 831px #fff,
            943px 1978px #fff, 1276px 1529px #fff, 600px 1419px #fff,
            1520px 233px #fff, 893px 1615px #fff, 458px 1390px #fff,
            456px 1947px #fff, 556px 533px #fff, 1381px 1339px #fff,
            1048px 1289px #fff, 1219px 814px #fff, 210px 1892px #fff,
            1933px 419px #fff, 1535px 1847px #fff, 237px 186px #fff,
            1197px 83px #fff, 1063px 1657px #fff, 146px 1175px #fff,
            208px 57px #fff, 1107px 968px #fff, 285px 859px #fff,
            220px 1705px #fff, 255px 1393px #fff, 1158px 1096px #fff,
            1807px 1002px #fff, 717px 1157px #fff, 1998px 1261px #fff,
            1154px 274px #fff, 1577px 622px #fff, 670px 1436px #fff,
            270px 1355px #fff, 1595px 1427px #fff, 223px 1246px #fff,
            1427px 1124px #fff, 678px 1410px #fff, 419px 583px #fff,
            1164px 785px #fff, 1501px 1577px #fff, 468px 1143px #fff,
            1493px 62px #fff, 1480px 1040px #fff, 1128px 848px #fff,
            114px 3px #fff, 987px 1827px #fff, 842px 1679px #fff,
            998px 12px #fff, 1857px 1111px #fff, 404px 973px #fff,
            902px 727px #fff, 335px 727px #fff, 1587px 1241px #fff,
            155px 381px #fff, 356px 1181px #fff, 724px 1410px #fff,
            1854px 116px #fff, 1599px 1213px #fff, 363px 1784px #fff,
            978px 1365px #fff, 504px 1885px #fff, 1515px 613px #fff,
            1508px 908px #fff, 1241px 71px #fff, 151px 890px #fff,
            1035px 1861px #fff, 1555px 1652px #fff, 77px 879px #fff,
            1107px 1381px #fff, 1098px 173px #fff, 1584px 145px #fff,
            986px 1409px #fff, 1999px 533px #fff, 759px 188px #fff,
            1903px 1717px #fff, 912px 163px #fff, 986px 1210px #fff,
            1266px 146px #fff, 159px 527px #fff, 1376px 333px #fff,
            93px 976px #fff, 346px 1289px #fff, 1231px 1544px #fff,
            1545px 1811px #fff, 724px 1818px #fff, 670px 27px #fff,
            634px 562px #fff, 1555px 1387px #fff, 981px 1253px #fff,
            142px 1201px #fff, 212px 814px #fff, 1776px 313px #fff,
            594px 22px #fff, 929px 1080px #fff, 805px 971px #fff,
            1133px 1580px #fff, 1881px 737px #fff, 1541px 952px #fff,
            1353px 1145px #fff, 290px 666px #fff, 1773px 736px #fff,
            4px 854px #fff, 1271px 1175px #fff, 85px 1261px #fff,
            557px 495px #fff, 1048px 564px #fff;
        animation: animStar 100s linear infinite;
    }

    .stars:after {
        content: " ";
        position: absolute;
        top: 2000px;
        width: 2px;
        height: 2px;
        background: transparent;
        box-shadow: 1190px 1949px #fff, 1346px 1667px #fff, 1870px 1954px #fff,
            430px 151px #fff, 1594px 817px #fff, 809px 1296px #fff,
            1761px 1022px #fff, 1367px 901px #fff, 316px 839px #fff,
            1115px 1557px #fff, 292px 1073px #fff, 901px 1329px #fff,
            900px 1643px #fff, 961px 97px #fff, 258px 900px #fff,
            93px 483px #fff, 878px 1223px #fff, 1995px 812px #fff,
            504px 463px #fff, 1373px 1522px #fff, 1162px 1132px #fff,
            820px 523px #fff, 1601px 1089px #fff, 1945px 1649px #fff,
            1903px 1366px #fff, 1425px 1657px #fff, 1046px 1349px #fff,
            1515px 598px #fff, 1932px 1128px #fff, 1380px 1206px #fff,
            652px 276px #fff, 1390px 876px #fff, 933px 1758px #fff,
            1803px 437px #fff, 711px 1756px #fff, 1894px 851px #fff,
            714px 74px #fff, 344px 1113px #fff, 1010px 1478px #fff,
            1882px 834px #fff, 1668px 1850px #fff, 1834px 1420px #fff,
            836px 896px #fff, 1716px 891px #fff, 583px 1433px #fff,
            188px 1265px #fff, 225px 100px #fff, 1385px 1765px #fff,
            340px 86px #fff, 1512px 1785px #fff, 556px 1540px #fff,
            1256px 479px #fff, 738px 1585px #fff, 1718px 1463px #fff,
            1587px 199px #fff, 939px 1413px #fff, 427px 1153px #fff,
            1344px 1960px #fff, 1290px 1682px #fff, 367px 1099px #fff,
            1196px 1478px #fff, 1323px 16px #fff, 689px 1542px #fff,
            1813px 1851px #fff, 161px 1741px #fff, 1813px 1967px #fff,
            1763px 625px #fff, 977px 1577px #fff, 1246px 1167px #fff,
            664px 310px #fff, 952px 789px #fff, 1626px 1441px #fff,
            560px 18px #fff, 1899px 1814px #fff, 140px 836px #fff,
            1925px 1027px #fff, 1342px 283px #fff, 297px 40px #fff,
            783px 169px #fff, 199px 1749px #fff, 478px 1905px #fff,
            690px 1376px #fff, 312px 693px #fff, 38px 323px #fff,
            769px 1062px #fff, 43px 1525px #fff, 1554px 1074px #fff,
            98px 1927px #fff, 169px 596px #fff, 1148px 986px #fff,
            821px 881px #fff, 1977px 697px #fff, 1709px 658px #fff,
            894px 1948px #fff, 1305px 750px #fff, 168px 831px #fff,
            943px 1978px #fff, 1276px 1529px #fff, 600px 1419px #fff,
            1520px 233px #fff, 893px 1615px #fff, 458px 1390px #fff,
            456px 1947px #fff, 556px 533px #fff, 1381px 1339px #fff,
            1048px 1289px #fff, 1219px 814px #fff, 210px 1892px #fff,
            1933px 419px #fff, 1535px 1847px #fff, 237px 186px #fff,
            1197px 83px #fff, 1063px 1657px #fff, 146px 1175px #fff,
            208px 57px #fff, 1107px 968px #fff, 285px 859px #fff,
            220px 1705px #fff, 255px 1393px #fff, 1158px 1096px #fff,
            1807px 1002px #fff, 717px 1157px #fff, 1998px 1261px #fff,
            1154px 274px #fff, 1577px 622px #fff, 670px 1436px #fff,
            270px 1355px #fff, 1595px 1427px #fff, 223px 1246px #fff,
            1427px 1124px #fff, 678px 1410px #fff, 419px 583px #fff,
            1164px 785px #fff, 1501px 1577px #fff, 468px 1143px #fff,
            1493px 62px #fff, 1480px 1040px #fff, 1128px 848px #fff,
            114px 3px #fff, 987px 1827px #fff, 842px 1679px #fff,
            998px 12px #fff, 1857px 1111px #fff, 404px 973px #fff,
            902px 727px #fff, 335px 727px #fff, 1587px 1241px #fff,
            155px 381px #fff, 356px 1181px #fff, 724px 1410px #fff,
            1854px 116px #fff, 1599px 1213px #fff, 363px 1784px #fff,
            978px 1365px #fff, 504px 1885px #fff, 1515px 613px #fff,
            1508px 908px #fff, 1241px 71px #fff, 151px 890px #fff,
            1035px 1861px #fff, 1555px 1652px #fff, 77px 879px #fff,
            1107px 1381px #fff, 1098px 173px #fff, 1584px 145px #fff,
            986px 1409px #fff, 1999px 533px #fff, 759px 188px #fff,
            1903px 1717px #fff, 912px 163px #fff, 986px 1210px #fff,
            1266px 146px #fff, 159px 527px #fff, 1376px 333px #fff,
            93px 976px #fff, 346px 1289px #fff, 1231px 1544px #fff,
            1545px 1811px #fff, 724px 1818px #fff, 670px 27px #fff,
            634px 562px #fff, 1555px 1387px #fff, 981px 1253px #fff,
            142px 1201px #fff, 212px 814px #fff, 1776px 313px #fff,
            594px 22px #fff, 929px 1080px #fff, 805px 971px #fff,
            1133px 1580px #fff, 1881px 737px #fff, 1541px 952px #fff,
            1353px 1145px #fff, 290px 666px #fff, 1773px 736px #fff,
            4px 854px #fff, 1271px 1175px #fff, 85px 1261px #fff,
            557px 495px #fff, 1048px 564px #fff;
    }

    @keyframes animStar {
        from {
            transform: translateY(0px);
        }

        to {
            transform: translateY(-2000px);
        }
    }
</style>
