<div class="stars" />

<style>
    .stars {
        margin-left: calc((100vw - 1920px) / 2);
        z-index: -1;
        border-radius: 50%;
        width: 3px;
        height: 3px;
        background: transparent;
        box-shadow: 1901px 136px #fff, 248px 772px #fff, 1954px 1844px #fff,
            1740px 655px #fff, 49px 1800px #fff, 1495px 551px #fff,
            1971px 1409px #fff, 684px 882px #fff, 1043px 705px #fff,
            843px 420px #fff, 1622px 1973px #fff, 63px 1479px #fff,
            982px 1475px #fff, 418px 430px #fff, 1729px 1684px #fff,
            216px 376px #fff, 1489px 880px #fff, 1617px 392px #fff,
            949px 78px #fff, 1854px 1705px #fff, 1706px 1850px #fff,
            1208px 353px #fff, 427px 1916px #fff, 783px 305px #fff,
            374px 770px #fff, 767px 1486px #fff, 918px 176px #fff,
            597px 484px #fff, 1391px 1680px #fff, 598px 531px #fff,
            542px 316px #fff, 197px 1428px #fff, 1008px 1780px #fff,
            1637px 10px #fff, 384px 249px #fff, 681px 1609px #fff,
            230px 957px #fff, 715px 245px #fff, 614px 1183px #fff,
            808px 169px #fff, 1263px 1911px #fff, 782px 1169px #fff,
            1865px 738px #fff, 240px 501px #fff, 186px 172px #fff,
            1166px 510px #fff, 491px 891px #fff, 1977px 893px #fff,
            970px 551px #fff, 40px 445px #fff, 987px 227px #fff,
            334px 1293px #fff, 1479px 1261px #fff, 887px 1055px #fff,
            4px 1998px #fff, 1132px 167px #fff, 647px 1085px #fff,
            1969px 335px #fff, 1485px 489px #fff, 1004px 773px #fff,
            983px 1826px #fff, 1229px 1935px #fff, 4px 1573px #fff,
            1225px 37px #fff, 225px 437px #fff, 1014px 1526px #fff,
            1308px 933px #fff, 394px 793px #fff, 1387px 1317px #fff,
            1146px 1059px #fff, 96px 1843px #fff, 1603px 9px #fff,
            690px 916px #fff, 1357px 721px #fff, 1805px 869px #fff,
            1270px 1819px #fff, 1219px 809px #fff, 564px 1973px #fff,
            61px 1564px #fff, 855px 809px #fff, 1569px 1975px #fff,
            540px 1864px #fff, 623px 803px #fff, 1014px 1946px #fff,
            348px 909px #fff, 859px 1312px #fff, 847px 287px #fff,
            422px 1557px #fff, 1471px 1681px #fff, 7px 1625px #fff,
            1291px 668px #fff, 1475px 720px #fff, 784px 530px #fff,
            790px 1222px #fff, 163px 1072px #fff, 753px 1993px #fff,
            1617px 1134px #fff, 1923px 98px #fff, 64px 510px #fff,
            1517px 624px #fff;
        animation: animStar 150s linear infinite;
    }

    .stars:after {
        content: " ";
        position: absolute;
        top: 2000px;
        width: 3px;
        height: 3px;
        background: transparent;
        box-shadow: 1901px 136px #fff, 248px 772px #fff, 1954px 1844px #fff,
            1740px 655px #fff, 49px 1800px #fff, 1495px 551px #fff,
            1971px 1409px #fff, 684px 882px #fff, 1043px 705px #fff,
            843px 420px #fff, 1622px 1973px #fff, 63px 1479px #fff,
            982px 1475px #fff, 418px 430px #fff, 1729px 1684px #fff,
            216px 376px #fff, 1489px 880px #fff, 1617px 392px #fff,
            949px 78px #fff, 1854px 1705px #fff, 1706px 1850px #fff,
            1208px 353px #fff, 427px 1916px #fff, 783px 305px #fff,
            374px 770px #fff, 767px 1486px #fff, 918px 176px #fff,
            597px 484px #fff, 1391px 1680px #fff, 598px 531px #fff,
            542px 316px #fff, 197px 1428px #fff, 1008px 1780px #fff,
            1637px 10px #fff, 384px 249px #fff, 681px 1609px #fff,
            230px 957px #fff, 715px 245px #fff, 614px 1183px #fff,
            808px 169px #fff, 1263px 1911px #fff, 782px 1169px #fff,
            1865px 738px #fff, 240px 501px #fff, 186px 172px #fff,
            1166px 510px #fff, 491px 891px #fff, 1977px 893px #fff,
            970px 551px #fff, 40px 445px #fff, 987px 227px #fff,
            334px 1293px #fff, 1479px 1261px #fff, 887px 1055px #fff,
            4px 1998px #fff, 1132px 167px #fff, 647px 1085px #fff,
            1969px 335px #fff, 1485px 489px #fff, 1004px 773px #fff,
            983px 1826px #fff, 1229px 1935px #fff, 4px 1573px #fff,
            1225px 37px #fff, 225px 437px #fff, 1014px 1526px #fff,
            1308px 933px #fff, 394px 793px #fff, 1387px 1317px #fff,
            1146px 1059px #fff, 96px 1843px #fff, 1603px 9px #fff,
            690px 916px #fff, 1357px 721px #fff, 1805px 869px #fff,
            1270px 1819px #fff, 1219px 809px #fff, 564px 1973px #fff,
            61px 1564px #fff, 855px 809px #fff, 1569px 1975px #fff,
            540px 1864px #fff, 623px 803px #fff, 1014px 1946px #fff,
            348px 909px #fff, 859px 1312px #fff, 847px 287px #fff,
            422px 1557px #fff, 1471px 1681px #fff, 7px 1625px #fff,
            1291px 668px #fff, 1475px 720px #fff, 784px 530px #fff,
            790px 1222px #fff, 163px 1072px #fff, 753px 1993px #fff,
            1617px 1134px #fff, 1923px 98px #fff, 64px 510px #fff,
            1517px 624px #fff;
    }

    @keyframes animStar {
        from {
            transform: translateY(0px);
        }

        to {
            transform: translateY(-2000px);
        }
    }
</style>
